import { useRouter } from 'next/router';
import styles from './hero-section-image.module.scss';
import Animated from '@/components/animated';
import Button from '@/components/button';
import Image from '@/components/image';
import { ctaEvents } from '@/lib/gtm';
import NumberedList from '@/components/numbered-list';
import PollenForecast from '../pollendata/PollenForecast';
import { useState } from 'react';

const defaultUniqueSellingPoints = [
	'Skippa videomöte',
	'Recept på 15 minuter',
	'0kr upp till 20år (annars 100kr)',
];

const getUSP = (site) => {
	switch (site) {
		case 'Aknekollen': {
			return [
				'Skippa videomöte',
				'Recept på 30 minuter',
				'0kr upp till 20år (annars 100kr)',
				'Din läkare följer upp för bästa resultat',
			];
		}
		case 'Ppillerkollen':
			return [];
		case 'Potenskollen':
			return ['Skippa videomöte', 'Recept på 30 minuter', 'En specialisttjänst av Doktor.se'];
		default:
			return defaultUniqueSellingPoints;
	}
};

const HeroSectionImage = ({
	topBackgroundImage,
	title,
	primaryActionButton,
	secondaryActionButton,
	configuration,
}) => {
	const router = useRouter();
	const site = configuration.site;
	const [showPollenForecast, setShowPollenForecast] = useState(false);
	const scrollDown = () => {
		scroll({
			top: window.innerHeight - 64, // 100vh minus navbar height
			behavior: 'smooth',
		});
	};

	const doktorPath = site !== 'Potenskollen' ? '/doktor' : '/';

	return (
		<section className={styles.heroSection}>
			<Image image={topBackgroundImage} className={styles.topBackgroundImage} alt="Image" />
			{site === 'Pollenkollen' && (
				<div className={`container ${showPollenForecast ? '' : 'd-none'}`}>
					<div className={`${styles.wrapper}`}>
						<PollenForecast
							show={showPollenForecast}
							hide={() => setShowPollenForecast(false)}
						/>
					</div>
				</div>
			)}
			<div className="container relative">
				<div>
					<Animated animation="slideInLeft">
						<h1 data-value={site}>{title}</h1>
					</Animated>
				</div>
				<Animated animation="slideInDown" delay={600}>
					<div className="row g-4">
						<div className="col">
							<div className={styles.uspContainer}>
								<NumberedList
									data={getUSP(site)}
									listItemType={'NonNumberedInverted'}
								/>
							</div>
							<Button
								text={primaryActionButton.title}
								onClick={() => {
									ctaEvents(
										'hero',
										configuration?.wizardConfiguration?.diagnosisType,
										configuration?.wizardConfiguration?.defaultVisitType ===
											'Revisit',
									);
									sessionStorage.setItem('currentUrl', router.asPath);
									router.push({
										pathname: doktorPath,
										query: {
											...(configuration?.wizardConfiguration
												?.defaultVisitType === 'Revisit'
												? { revisit: true }
												: null),
										},
									});
								}}
							/>
							{secondaryActionButton && (
								<div>
									<Animated animation="slideInDown" delay={800}>
										<div className="pt-5">
											<Button
												text={secondaryActionButton.title}
												onClick={() => {
													ctaEvents(
														'hero',
														configuration?.wizardConfiguration
															?.diagnosisType,
														'revisit',
													);
													sessionStorage.setItem(
														'currentUrl',
														router.asPath,
													);
													router.push({
														pathname: doktorPath,
														query: {
															revisit: true,
														},
													});
												}}
												type="secondary"
											/>
										</div>
									</Animated>
								</div>
							)}
							{site === 'Pollenkollen' && (
								<Animated animation="slideInDown" delay={800}>
									<div style={{ paddingTop: '35px' }}>
										<Button
											text="Pollenprognos"
											onClick={() => {
												setShowPollenForecast(true);
											}}
											type="secondary"
										/>
									</div>
								</Animated>
							)}
						</div>
					</div>
				</Animated>
			</div>
			<div className={styles.scrollDownButton}>
				<Button
					onClick={scrollDown}
					type="icon"
					icon="/icons/angle-down.svg"
					text="Scrolla ner"
				/>
			</div>
		</section>
	);
};

export default HeroSectionImage;