import { getGlobalConfig, getHomeConfig, transformStaticProps } from '@/lib/api';
import styles from '../styles/index.module.scss';
import Layout from '@/components/layout';
import Text from '@/components/text';
import ImageCardQuote from '@/components/image-card-quote';
import Link from '@/components/link';
import PixelatedBorder from '@/components/pixelated-border';
import SitesSection from '@/components/sites-section';
import HeroSection from '@/components/hero-section';
import HeroSectionImage from '@/components/hero-section-image';
import ListSection from '@/components/list-section';
import IllustrationSection from '@/components/illustration-section';
import { useEffect } from 'react';
import { pageEvent } from '@/lib/gtm';
import HeroPromoKollar from '@/components/hero-promo-kollar';

const Home = ({ config }) => {
	useEffect(() => {
		pageEvent();
	}, [config]);

	return (
		<Layout {...config} useSelfReferringCanonicalUrl={true}>
			{!!config.heroSection ? (
				<HeroSection
					title={config.heroSection.heading}
					subHeader={config.heroSection.subHeading}
					ctaButton1={config.heroSection?.ctaButton1}
					ctaButton2={config.heroSection?.ctaButton2}
					introVideo={config.heroSection.introVideo}
					backgroundColor={config.heroSection?.backgroundColor}
				/>
			) : (
				<HeroSectionImage
					title={config.title}
					topBackgroundImage={config.topBackgroundImage}
					primaryActionButton={config.primaryActionButton}
					secondaryActionButton={config.secondaryActionButton}
					configuration={config.configuration}
				/>
			)}
			{config.configuration.site === 'Kollarnase' && <HeroPromoKollar config={config} />}
			<ListSection
				title={config.listSection.title}
				listItem={config.listSection.listItem}
				subtitle={config.listSection.subtitle}
				testimonialsDeprecated={config?.testimonialSection?.testimonial}
				testimonialTextColor={config?.testimonialSection?.testimonialTextColor}
				testimonialBackgroundColor={config?.testimonialSection?.testimonialBackgroundColor}
				tryggEHandelId={config.configuration?.tryggEHandelId}
			/>
			<ImageCardQuote
				alt={config.doctorQuote.image.alt}
				heading={config.doctorQuote.title}
				heading2={config.doctorQuote.title2}
				bodyContent={config.doctorQuote.quote}
				bodyContent2={config.doctorQuote.quote2}
				listLinkText={config.doctorQuote.listLinkText}
				listLinkURL={config.doctorQuote.listLinkURL}
				listLinkInNewTab={config.doctorQuote.listLinkInNewTab}
				quoteReference={config.doctorQuote.name + '\n' + config.doctorQuote.occupation}
				quoteImage={config.doctorQuote.image}
				imageOnRight={true}
				textColor={config.doctorQuote?.textColor}
				backgroundColor={config.doctorQuote?.backgroundColor}
			/>
			{!!config.aboutUs && (
				<div className={`container-fluid`}>
					<div className="row">
						<div
							className={
								styles.aboutUs +
								' ' +
								styles[config.aboutUs.backgroundColor?.toLowerCase() ?? 'primary']
							}
						>
							<PixelatedBorder
								flip
								secondary={
									(config.aboutUs.backgroundColor?.toLowerCase() ?? 'primary') ===
									'secondary'
								}
							/>
							<div className={styles.aboutUsContent}>
								<Text
									tag="body"
									variant="h1"
									color={
										(config.aboutUs.backgroundColor?.toLowerCase() ??
											'primary') === 'primary'
											? '#000000'
											: '#FFFFFF'
									}
								>
									{config.aboutUs.title}
								</Text>
								<div className={styles.bounceLeftAndRight}>
									<Link
										href={`/${config.aboutUs.url}`}
										variant="image"
										icon="icons/arrow-right.svg"
										text="Arrow right"
										iconWidth={32}
										iconHeight={32}
									/>
								</div>
							</div>
							<PixelatedBorder
								secondary={
									(config.aboutUs.backgroundColor?.toLowerCase() ?? 'primary') ===
									'secondary'
								}
							/>
						</div>
					</div>
				</div>
			)}
			<IllustrationSection
				illustrationQuoteListLink={
					config?.illustrationQuoteSection?.illustrationQuoteListLink
				}
				backgroundImageWithBallAndText={config.backgroundImageWithBallAndText}
				illustrationQuote={config?.illustrationQuoteSection?.illustrationQuote}
				illustrationQuoteTitle={config?.illustrationQuoteSection?.illustrationQuoteTitle}
				mascot={config.configuration.mascot}
			/>
			{!!config?.showSymbolSiteLinks && (
				<SitesSection
					symbolSiteLinks={config.configuration?.globalKollarnaLinks}
					navbarMode={!!config.symbolSiteLinkNavbarMode}
				/>
			)}
		</Layout>
	);
};

export const getStaticProps = async ({ preview, previewData }) => {
	return await transformStaticProps({
		configuration: { ...(await getGlobalConfig(!!preview, previewData)) },
		...(await getHomeConfig(!!preview, previewData)),
		preview: !!preview,
		previewData: previewData ?? null,
	});
};

export default Home;
